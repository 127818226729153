<template>
  <Title pageTitle="COMPANY" pageDiscrption="特定商取引法の表記" />
  <div class="container">
    <div class="info">
      <div class="info__items">
        <div class="info__items--title">販売事業者 / 店舗</div>
        <div class="info__items--content">m&amp;s Fabric</div>
      </div>
      <div class="info__items">
        <div class="info__items--title">代表</div>
        <div class="info__items--content">松尾 孝宏</div>
      </div>
      <div class="info__items">
        <div class="info__items--title">販売責任者</div>
        <div class="info__items--content">斉藤 博夫</div>
      </div>
      <div class="info__items">
        <div class="info__items--title">所在地</div>
        <div class="info__items--content">
          〒275-0001 千葉県習志野市東習志野8-9-2
        </div>
      </div>
      <div class="info__items">
        <div class="info__items--title">TEL</div>
        <div class="info__items--content">047-478-3013</div>
      </div>
      <div class="info__items">
        <div class="info__items--title">E-mail</div>
        <div class="info__items--content">info@ms-fabric.com</div>
      </div>
      <div class="info__items">
        <div class="info__items--title">URL</div>
        <div class="info__items--content">https://www.ms-fabric.com/</div>
      </div>
      <div class="info__items">
        <div class="info__items--title">商品以外の必要料金</div>
        <div class="info__items--content para-end">
          送料、代引き手数料（詳細金額はショッピングガイドをご確認下さい）各種金融機関へ振込の場合は振込手数料をご負担頂いております。
        </div>
      </div>
      <div class="info__items">
        <div class="info__items--title">返品について</div>
        <div class="info__items--content">
          <p>
            返品の可否：各種オーダーメイドによる受注生産製品は基本的に返品はお断り願います。
          </p>
          <p>
            返品の条件：不良品などの当店の不手際による商品。お客様の過失により破損した商品は不可とさせて頂きます。
          </p>
          <p class="para-end">
            返品にかかる送料負担の有無：不良品などの当店の不手際による返品の場合の送料は当店で負担させていただきます。送料着払いにて、到着後1週間以内にお送り下さい。
          </p>
        </div>
      </div>
      <div class="info__items">
        <div class="info__items--title">商品の保証</div>
        <div class="info__items--content para-end">
          納品後14日以内にサイズが大きく変わってしまった場合は、当店保証規定により返品・交換・再製作の対応をさせていただきます。保証規定につきましては当店「ショッピング」ページをご確認ください。
        </div>
      </div>
      <div class="info__items">
        <div class="info__items--title">引き渡し時期</div>
        <div class="info__items--content para-end">
          決済後7日以内に発送させて頂いております。ご注文が集中した場合や注文数によって異なる場合がありますので予めご了承ください。発送準備が整い次第、改めてお届け日のご連絡をさせて頂きます。
        </div>
      </div>
      <div class="info__items">
        <div class="info__items--title">お支払方法</div>
        <div class="info__items--content">
          ヤマト運輸、宅配便にてお届け ※混雑時は他社の場合有
        </div>
      </div>
      <div class="info__items">
        <div class="info__items--title">取り扱い商品</div>
        <div class="info__items--content">
          日本国内製造によるオーダーメイドTシャツ
        </div>
      </div>
      <div class="info__items">
        <div class="info__items--title">特記事項</div>
        <div class="info__items--content para-end">
          ご注文の前に「送料」「支払方法」「返品規約」等の記載内容をご確認下さいますようお願い致します。
        </div>
      </div>
      <h3 class="heading-3 info__title">個人情報保護ポリシーについて</h3>
      <p class="info__note">
        当店では個人情報について以下の取り組みを厳正に実施致しております
      </p>
      <div class="info__items">
        <div class="info__items--title">収集の目的</div>
        <div class="info__items--content para-end">
          ご注文頂いた商品の発送に必要な情報（お名前、
          住所、電話番号、メールアドレス等)、オーダーメイドTシャツ製作に必要なサイズ情報に限り収集させて頂きます。
        </div>
      </div>
      <div class="info__items">
        <div class="info__items--title">情報の利用</div>
        <div class="info__items--content">
          <p class="para">
            上記の目的以外には、お客様の有益と思われる当店のサービスや
            キャンペーンのお知らせ等をメールにてお知らせする場合がございます。
          </p>
          <p class="para-end">
            ただし、お客様より中止の申し出があった場合、即刻送信を中止致します。
            また収集した個人情報はお客様の承諾無く第三者に提供、開示は一切致しません。
          </p>
        </div>
      </div>
      <div class="info__items">
        <div class="info__items--title">管理責任</div>
        <div class="info__items--content para-end">
          収集した個人情報は当社の厳正なる管理下において責任を持って
          保管し、当店以外の者には閲覧、持ち出しを一切禁止致します。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '../components/Title.vue'
export default {
  components: {
    Title,
  },
}
</script>

<style lang="scss" scoped>
.info {
  max-width: 760px;
  margin: 0 auto;

  &__items {
    min-height: 60px;
    padding: 20px 0;
    display: grid;
    grid-template-columns: 230px 1fr;
    align-items: center;
    border-bottom: 1px solid var(--color-dark);

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      text-align: center;
    }

    &--title {
      font-size: 12px;
      text-align: center;

      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    &--content {
      font-size: 13px;
      padding-right: 20px;

      @media screen and (max-width: 767px) {
        padding-right: 0;
      }
    }
  }

  &__title {
    text-align: center;
    margin-top: 10rem;
  }

  &__note {
    text-align: center;
    padding: 10px;
    background-color: #444;
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 300;
  }
}
</style>
